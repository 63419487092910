import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initLazyLoad from './modules/lazyload';
import initVideo from './modules/video';
import initNav from './modules/nav';

async function init() {
  // Optional polyfills
  // const polyfills = [];
  // if (!('Feature' in window)) polyfills.push(import('feature-polyfill'));
  // await Promise.all(polyfills);

  // Immediate
  initLazyLoad();
  initVideo();
  initNav();

  // Lazyloaded
  // const modules = ['tracking'];

  // for (const module of modules) {
  //   import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  // }

  // Conditionally lazyloaded
  const conditionalModules = {
    '.js-iframe': 'iframe',
    '.js-contact-overlay': 'contact-overlay',
    '.js-form': 'form',
    '.js-more': 'more',
    '.js-textarea': 'textarea',
    '.js-accordion': 'accordion',
    '.js-tooltip': 'tooltip'
  };

  for (const [selector, module] of Object.entries(conditionalModules)) {
    if ($(selector)) import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  }
}

init();
