import { $ } from '../helpers/query-selector';
import { viewport } from '../helpers/variables';

function init() {
  const header = $('.js-header');
  const navToggle = $('.js-nav-toggle');
  const navIcon = $('.js-nav-icon');
  const navIconActiveClass = 'c-nav-icon--active';
  const navActiveClass = 'l-header--nav-active';

  function openNav() {
    navIcon.classList.add(navIconActiveClass);
    navToggle.setAttribute('aria-expanded', true);

    header.classList.add(navActiveClass);
  }

  function closeNav() {
    navIcon.classList.remove(navIconActiveClass);
    navToggle.setAttribute('aria-expanded', false);

    header.classList.remove(navActiveClass);
  }

  navToggle.addEventListener('click', () => {
    if (header.classList.contains(navActiveClass)) {
      closeNav();
    } else {
      openNav();
    }
  });

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') closeNav();
  });

  // Close nav on viewport switch
  viewport.m.addEventListener('change', e => {
    if (header.classList.contains(navActiveClass)) {
      closeNav();
    }
  });

  // Close nav on contact overlay click
  const contactButton = $(
    '.l-nav__mobile button[data-a11y-dialog-show="overlay"]'
  );

  contactButton.addEventListener('click', () => {
    if (header.classList.contains(navActiveClass)) {
      setTimeout(() => {
        closeNav();
      }, 500);
    }
  });
}

export default init;
